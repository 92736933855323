<template>
    <v-container fluid style="padding:0" v-if="sale">
        <table style="width:100%">
            <thead>
                <tr>
                    <td style="border:none">
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="border:none">
                        <div class="content">
                            <v-container fluid style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0   ">
                                <v-row class="justify-center">
                                    <h2>وەسڵی فرۆشتن</h2>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <p>
                                            بەروار: {{ sale.date.split('T')[0] }} <br>
                                            ژمارەی وەسڵ: {{ sale.sale_id }}<br>
                                            ناوی کڕیار: {{ sale.customer_name }} <br>
                                            کۆدی كڕیار: {{ sale.mobile }} <br>
                                            تێبنی: {{ sale.note }}


                                        </p>
                                    </v-col>

                                </v-row>
                            </v-container>
                            <v-container fluid style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:0   ">
                                <table id="tablee"
                                    :style="'width:100%;' + ([18, 19, 20, 21, 22, 23].includes(sale.sale_items.length) ? 'page-break-after: always' : '')">
                                    <thead>
                                        <tr>
                                            <th class="text-right" style="font-size:15px !important; color: black;">#</th>
                                            <th class="text-right" style="font-size:15px !important; color: black;">کۆد</th>
                                            <th class="text-right" style="font-size:15px !important; color: black;">ناوی
                                                کاڵا</th>
                                            <th class="text-right" style="font-size:15px !important; color: black;"> یەکە
                                            </th>
                                            <th class="text-right" style="font-size:15px !important; color: black;"> دانە
                                            </th>
                                            <th class="text-right" style="font-size:15px !important; color: black;"> کۆی
                                                دانە</th>
                                            <th class="text-right" style="font-size:15px !important; color: black;">نرخ</th>
                                            <th class="text-right" style="font-size:15px !important; color: black;">سەرجەم
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, i) in sale.sale_items" :key="i">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ item.item_code }}</td>
                                            <td>{{ item.item_name }}</td>
                                            <td>{{ item.qty_unit }}</td>
                                            <td>{{ item.qty_item }}</td>
                                            <td>{{ (item.qty_unit * item.item_per_unit) + item.qty_item }}</td>
                                            <td>${{ item.item_price }}</td>
                                            <td>${{ (((item.qty_unit * item.item_per_unit) + item.qty_item) *
                                                item.item_price).toFixed(2) }}</td>
                                        </tr>
                                    </tbody>

                                </table>
                                <br>
                                <table id="tablee">
                                    <tr>
                                        <th style="font-size:15px !important; color: black;text-align:right">سەرجەم</th>
                                        <td style="text-align: left;font-size:15px !important; color: black;">
                                            ${{ Number((sale.total + sale.discount).toFixed(2)).toLocaleString() }}</td>
                                    </tr>
                                    <tr>
                                        <th style="font-size:15px !important; color: black;text-align:right">داشکاندن</th>
                                        <td style="text-align: left;font-size:15px !important; color: black;">
                                            ${{ Number(sale.discount.toFixed(2)).toLocaleString() }}</td>
                                    </tr>
                                    <tr>
                                        <th style="font-size:15px !important; color: black;text-align:right">سەرجەم دوای
                                            داشکاندن</th>
                                        <td style="text-align: left;font-size:15px !important; color: black;">
                                            ${{ Number(sale.total.toFixed(2)).toLocaleString() }}</td>
                                    </tr>
                                    <tr>
                                        <th style="font-size:15px !important; color: black;text-align:right">واصل کراوە</th>
                                        <td style="text-align: left;font-size:15px !important; color: black;">
                                            ${{ sale.payment_type ==
                                                'c' ? Number(sale.total.toFixed(2)).toLocaleString() :
                                                Number(sale.paid.toFixed(2)).toLocaleString() }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style="font-size:15px !important; color: black;text-align:right">کۆی قەرز</th>
                                        <td style="text-align: left;font-size:15px !important; color: black;">
                                            ${{ Number(((sale.debt + (
                                                sale.sale_type == 'rs' ? - sale.total : sale.total
                                            )) - (sale.payment_type
                                                == 'c' ? sale.total : sale.paid)).toFixed(2)).toLocaleString() }}</td>
                                    </tr>
                                </table>
                            </v-container>

                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td style="border:none">
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="header" style="width: 100%;">
            <img src="@/assets/header.jpg" style="width:100%">

        </div>
        <div class="footer">
            <img src="@/assets/footer.jpg" style="width:100%">
        </div>


    </v-container>
</template>

<script>
export default {
    created() {
        this.getSale()

    },
    data() {
        return {
            sale: null,

        }
    },
    methods: {
        async getSale() {
            const { data } = await this.axios.get('/sale/' + this.$route.params.sale_id)
            this.sale = data

        },
    },
    updated() {
        setTimeout(() => {
            window.print();
            this.$router.back()
        }, 1000);
    }
}
</script>

<style scoped>
#app {
    background-color: rgb(255, 255, 255) !important;
}

#tablee {
    border: 1px solid black;
    border-collapse: collapse;
}

#tablee th,
td {
    border: 1px solid black;
    padding: 4px;
}

tr {
    background-color: rgb(255, 255, 255) !important;
}

td {
    font-size: 12px !important;
    height: 22px !important;
}

p {
    font-size: 12px !important;
}

/* #table{
    border: 1px solid black !important;
} */



.header-space {
    height: 175px;
}

.footer-space {
    height: 100px;
}

.header {
    position: fixed;
    top: 10px;
    height: 50px;

}

.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;

}
</style>